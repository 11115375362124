@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



html,body {
  overflow-x: hidden;
   margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* ===== Scrollbar CSS ===== */
/* Firefox */
* {  
  scrollbar-width: 5px;
  scrollbar-color: #2b06ff;
  /* color: rgb(90, 37, 37) !important; */
  
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 17px;
}

*::-webkit-scrollbar-track {
  background: #131313;
}

*::-webkit-scrollbar-thumb {
  background-color: #f3e9e9;
  border-radius: 10px;
  /* visibility: hidden; */
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}






















.flip-text {
  display: inline-block;
  animation: flip-animation 5s infinite;
}

@keyframes flip-animation {
  0%, 100% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(360deg);
  }
}

/* styles.css */

.supply-chain-section {
  position: relative;
  background-image: url('https://saivleen.com/wp-content/uploads/2024/05/gourmet-chocolate-dessert-wooden-table-decorated-with-spices-generated-by-ai.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Ensure full height of viewport */
}

.supply-chain-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}



.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #000000; /* Your primary color */
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-field:focus {
  outline: none;
  border-color: #df794d; /* Your primary color */
  box-shadow: 0 0 0 0.2rem rgba(223, 121, 77, 0.25); /* Adjust shadow color as needed */
}


@media only screen and (max-width:639px) {
  .mobile{
   display: none;
  }
 }






 .spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #000000;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}









.btn-pink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  /* width: fit-content; */
  color: white;
  padding: 0.5rem;
  /* box-shadow: 5px 5px 7px 0px #ef476f3f; */
  font-size: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 500;
  border: solid 3px transparent;
  position: relative;
  z-index: 100;
  /* border-radius: 5px; */
}

.btn-pink::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: all 0.8s;
}

.btn-pink:hover::before {
  transform: scaleX(1);
}

.btn-pink:hover {
  border: solid 3px #115a15;
  /* color: green; */
  scale: 1.1;
} 
